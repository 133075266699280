<template>
  <div class="white--text grey lighten-3 pa-0" fluid>
    <simple-header
      :title="successful ? 'پرداخت موفق' : 'پرداخت ناموفق'"
      :routes="[
        { title: 'خانه', url: '/' },
        {
          title: successful ? 'پرداخت موفق' : 'پرداخت ناموفق',
        },
      ]"
    />
    <v-container>
      <v-card class="rounded-xl moholand-shadow">
        <v-card-text class="text-center pa-8 black--text">
          <div v-if="successful">
            <v-icon color="green" size="100">
              mdi-check-circle-outline
            </v-icon>
            <h1 class="green--text mt-4">
              تبریک با موفقیت خرید کردی!
            </h1>
            <h3 class="mt-4">شماره پیگیری: {{ data.transaction_id }}</h3>
            <v-row class="mt-2">
              <v-col
                cols="12"
                class="pa-1"
                v-for="(item, i) in links"
                :key="'item' + i"
              >
                <v-btn
                  large
                  class="rounded-lg white--text"
                  color="orange darken-1"
                  @click="download(item)"
                >
                  {{ item.name }}
                </v-btn>
              </v-col>
              <v-col cols="12" class="pa-1">
                <v-btn text class="rounded-lg" to="/mydownloads">
                  رفتن به صفحه دانلود ها
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-icon color="red" size="100">
              mdi-close-circle-outline
            </v-icon>
            <h1 class="red--text mt-4">
              پرداخت ناموفق، نتونستی خرید کنی!
            </h1>
            <div class="rounded-lg mt-4">
              {{ message }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MyAxios from "@/constants/MyAxios";
import SimpleHeader from "../../components/SimpleHeader.vue";

export default {
  name: "Payment",
  components: {
    SimpleHeader,
  },
  data() {
    return {
      payment: this.$route.params.payment || "",
      loading: true,
      successful: true,
      message: "",
      code: 0,
      data: {
        ref_id: null,
        transaction_id: null,
        price: null,
        updated_at: null,
      },
      links: [],
      loading: false,
    };
  },
  created() {
    this.$root.$emit("globalLoadingSet", true);
    Promise.all([this.getVerify()]).then((response) => {
      console.log(response[0]);
      if (response[0]) this.$root.$emit("globalLoadingSet", false);
    });
  },
  methods: {
    async getVerify() {
      return MyAxios.get("payments/verify/" + this.$route.params.payment, {
        params: {
          transaction_id: this.$route.query.Authority,
          Status: this.$route.query.Status,
        },
      })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.successful = response.data.code > 0;
          this.message = response.data.message;
          this.code = response.data.code;
          this.data = response.data.payment;
          if (this.successful) return this.getProducts();
          else return true;
        })
        .catch((error) => {
          this.loading = false;
          return false;
        });
    },
    async getProducts() {
      this.$root.$emit("removeCart");
      return MyAxios.get("orders/links/" + this.$route.params.payment)
        .then((response) => {
          console.log(response);
          this.links = response.data
            .filter((val) => !!val)
            .reduce(
              (a, current) =>
                current &&
                current.links_media &&
                a.concat(
                  current.links_media.map((val) => ({
                    id: val?.id,
                    url: val?.url || val,
                    name: current.name || current.title || "",
                    product_id: current.id,
                  }))
                ),
              []
            );
          //   response.data
          return true;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          return false;
        });
    },
    download(item) {
      this.increaseDownloadCount(item);
      window.open(item.url || item, "_blank");
    },
    increaseDownloadCount(item) {
      if (this.$store.state.userData?.id)
        MyAxios.post("/downloads/create", {
          user_id: this.$store.state.userData?.id,
          media_id: item?.id || null,
          product_id: item.product_id,
        });
    },
  },
};
</script>

<style scoped>
.ico-success {
  box-shadow: 0 0 0px 7px #92da97, 0 0 4px 13px #cefbd0;
}
.error {
  box-shadow: 0 0 0px 7px #fd7373, 0 0 4px 13px #fbcece;
}
.ltr {
  direction: ltr;
}
</style>
